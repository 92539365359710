import React from 'react';

import './App.css';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = {
  appbar: {
      backgroundColor: "grey"
  }	
}

const menus = ["Home", "Portfolio Manager", "Digit Recognizer", "AudioGS", "Memory Game", "QA"];
const links = ["/", "http://namegraphs.com/", "http://dr1.dynu.net/", "http://audiogs.giize.com/", "http://p-menu.dynu.net/memory/", "http://qa-chat.giize.com/"];

function App() {
    return (
    <div className="App">
    <AppBar position="static" style={styles.appbar}>
    <Toolbar>
      <Typography variant="h6" color="inherit">
        Apps
      </Typography>
    </Toolbar>
    </AppBar>
    
    <Grid container component="main">
    
    <Grid item>
    
    <MenuList style={{width:200}}>
      {menus.map((text, index) => (
	<MenuItem button key={index} onClick={() => window.location = links[index]}>{menus[index]}</MenuItem>
       ))}
    </MenuList>
    </Grid>
    
    <Grid item>
      <p>Some experiments..</p>
      </Grid>
      </Grid>
    </div>
  );
}

export default App;
